import { useRouteError, isRouteErrorResponse } from "react-router-dom";

function ErrorPage() {
    const err = useRouteError();
    if (isRouteErrorResponse(err)) {
        console.error(err);
        return (
            <div>
                <p>
                    Error {err.status}: {err.statusText}
                </p>
                {err.data?.message && <p>{err.data.message}</p>}
            </div>
        );
    }
    console.error(err);
    return <p>Unknown Error!</p>;
}

export default ErrorPage;
